.Message {
  h1,
  h2,
  h3 {
    line-height: 1.2;
  }

  h3,
  h4,
  h5,
  h6 {
    line-height: 1;
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  code {
    white-space: break-spaces;
  }
}
