@import "/src/ui/styles/variables";

.UserMessageWrapper {
  @apply p-3 bg-gray-150 w-fit rounded-xl shrink text-dark-text relative;

  &:after {
    @apply bg-gray-150;
    position: absolute;
    bottom: -5px;
    left: 3.5px;
    border-radius: 50%;
    content: " ";
    width: 10px;
    height: 10px;
  }

  &:before {
    @apply bg-gray-150;
    position: absolute;
    top: calc(100% + 5px);
    left: 0px;
    border-radius: 50%;
    content: " ";
    width: 4px;
    height: 4px;
  }
}
