@use "~ag-grid-community/styles" as ag;

@import "../variables";

@include ag.grid-styles(
  (
    theme: alpine,
    header-background-color: null,
    font-family: (
      "IBM Plex Sans",
      "sans-serif",
    ),
  )
);

.ag-theme-alpine {
  .ag-header-cell-text {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: #494e5b;
  }

  .ag-header {
    border-bottom: none;
  }

  .ag-root-wrapper {
    border: none;
  }

  // Custom checkbox styles
  .ag-checkbox-input-wrapper {
    position: relative;
    width: 24px;
    height: 24px;

    &:hover::before {
      border-color: rgba(0, 0, 0, 0.20);
    }

    &::before {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      border: 2px solid rgba(0, 0, 0, 0.10);
      border-radius: 4px;
      background-color: transparent;
      transition: all 0.2s ease;
      cursor: pointer;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 45%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 7px;
      height: 12px;
      border: solid transparent;
      border-width: 0 3px 3px 0;
      transition: all 0.2s ease;
    }

    &.ag-checked {
      &::before {
        background-color: $color-primary;

        &:hover {
          border-color: $color-primary-400;
        }
      }

      &::after {
        border-color: black;
      }

      
    }

    &.ag-indeterminate {
      &::before {
        background-color: $color-primary;

        &:hover {
          border-color: $color-primary-400;
        }
      }

      &::after {
        content: "";
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 12px;  
        height: 3px;
        border: none;
        background-color: black;
        pointer-events: none;
      }
    }

    input {
      width: 24px;
      height: 24px;
      margin: 0;
      opacity: 0;
      cursor: pointer;
      background-color: transparent;
    }
  }
}

.m9-ag-grid-theme {
  .ag-root-wrapper {
    background: transparent;
  }

  .ag-header {
    background: #fff;
    box-shadow: 0 24px 32px -16px rgba(161, 174, 183, 0.15);
    border-radius: 0 0 12px 12px;
  }

  .ag-row {
    background: transparent;

    .ag-cell {
      display: flex;
      align-items: center;
    }
  }
}
