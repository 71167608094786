@import "/src/ui/styles/variables";

.Loader {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;

  .DotFlashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $color-gray-400;
    color: $color-gray-400;
    animation: DotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .DotFlashing::before,
  .DotFlashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .DotFlashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $color-gray-400;
    color: $color-gray-400;
    animation: DotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .DotFlashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $color-gray-400;
    color: $color-gray-400;
    animation: DotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes DotFlashing {
    0% {
      background-color: $color-gray-500;
    }
    50%,
    100% {
      background-color: $color-gray-300;
    }
  }
}
