// Colors default
$color-semantic-green-400: #136741;
$color-semantic-green-600: #093622;

// Colors
$color-primary: var(--main-color);
$color-primary-0: var(--main-color-0);
$color-primary-100: var(--main-color-100);
$color-primary-200: var(--main-color-200);
$color-primary-300: var(--main-color-300);
$color-primary-400: var(--main-color-400);
$color-primary-600: var(--main-color-600);
$color-primary-400: var(--main-color-400);
$color-secondary-200: #d8c792;
$color-complementary-400: #6fb2cd;
$color-complementary-200: #a0d9f0;
$color-gray-100: #fcfcfd;
$color-gray-150: #f4f5f6;
$color-gray-200: #e6e8ec;
$color-gray-300: #b1b5c4;
$color-gray-400: #777e91;
$color-gray-500: #494e5b;
$color-gray-light: #d1d5db;

$color-black: #000;
$color-white: #fff;

$color-error: "#EF466F";

$color-bullpen-primary-50: #fbfee8;
$color-bullpen-primary-300: #f7ff5c;
$color-bullpen-primary-500: #ece406;
$color-bullpen-primary-900: #725311;
$color-bullpen-primary-950: #432d05;

$color-bullpen-secondary-600: #0c6497;
$color-bullpen-secondary-700: #094b72;
$color-bullpen-secondary-800: #06324c;
$color-bullpen-secondary-900: #031926;
$color-bullpen-secondary-950: #01080c;

$color-bullpen-red-600: #ed004f;
$color-bullpen-red-950: #570020;

$color-bullpen-gray-900: #111827;

// Box shadow
$depth-1: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
$depth-2: 0px 24px 32px -16px rgba(161, 174, 183, 0.15);

:export {
  bullpen-secondary-950: $color-bullpen-secondary-950;
}
